<template>
  <Page :classes="['page--vcenter']">

    <form class="auth" @submit="true">
      <div class="auth__header">
        <h1 class="auth__title">{{$t('auth.sign_in')}}</h1>
      </div>

      <p class="auth__alert auth__alert--wrong" v-if="wrongLogin">
        {{$t('error.wrong_login')}}
      </p>

      <InputEmail
        name="email"
        :label="$t('common.email')"
        v-model.trim="email"
        :focusOnMount="true"
        :isRequired="true"
      ></InputEmail>

      <InputPassword
        name="password"
        :label="$t('common.password')"
        v-model.trim="password"
        @enter="sendLoginRequest"
        :isRequired="true">
        <template #link>
          <router-link :to="{ name: 'recover-password', params: { locale: $i18n.locale } }" tabindex="-1">
            {{$t('auth.forgoten_password')}}
          </router-link>
        </template>
      </InputPassword>

      <Button
        :isLoading="isLoading"
        :isDisabled="isInvalid"
        :classes="['btn--accent', 'btn--wide']"
        @onclick="sendLoginRequest()">
          <span v-show="!isLoading">{{$t('auth.login')}}</span>
          <span v-show="isLoading">{{$t('auth.signing_in')}}</span>
        </Button>

      <p class="auth__link">
        {{$t('auth.you_dont_have_an_account')}}
        <router-link :to="{name: 'register', params: { locale: $i18n.locale }}">
          {{$t('auth.create_account')}}
        </router-link>
      </p>
    </form>

  </Page>
</template>

<script>
import Check from '@/services/check/Check';
import InputEmail from '@/components/inputs/email/InputEmail.vue';
import InputPassword from '@/components/inputs/password/InputPassword.vue';
import UrlService from '@/services/url/UrlService';

const urlService = new UrlService();

export default {
  name: 'Login',
  components: {
    InputEmail,
    InputPassword,
  },
  data() {
    return {
      isLoading: false,
      wrongLogin: false,
      email: '',
      password: '',
    };
  },
  computed: {
    isInvalid() {
      return !Check.isValidEmail(this.email)
        || !Check.isValidPassword(this.password);
    },
    storedEmail: {
      get() {
        return localStorage.getItem('Login.email') || '';
      },
      set(value) {
        localStorage.setItem('Login.email', value);
      },
    },
  },
  mounted() {
    // Check if user is logged
    this.$store.dispatch('prelogin')
      .then(() => {
        this.goToHome();
      })
      .catch(() => {
        this.email = this.storedEmail;
      });
  },
  methods: {
    sendLoginRequest() {
      if (this.isInvalid) return;

      this.isLoading = true;
      this.$store.dispatch('login', {
        email: this.email,
        password: this.password,
      }).then(() => {
        this.wrongLogin = false;
        this.goToHome();
      }).catch(() => {
        this.wrongLogin = true;
      }).finally(() => {
        this.isLoading = false;
      });
    },
    goToHome() {
      this.$store.dispatch('getSelf')
        .then((data) => {
          if (data.is_staff) {
            this.$router.push({ name: 'report-challenges', params: { locale: this.$i18n.locale } });
          } else if (data.entities.length) {
            this.$router.push(urlService.entityRoute(data.entities[0], this.$i18n.locale));
          } else {
            this.$router.push({ name: 'new-entity', params: { locale: this.$i18n.locale } });
          }
        });
    },
  },
  watch: {
    email: {
      handler(val) {
        this.storedEmail = val;
      },
    },
  },
};
</script>
